<template>
  <div class="course-order-card" @click="cardOnClick">
    <div class="course-order-status" :class="courseOrderStatusClassMap[detail.courseOrderStatus]">
      {{ courseOrderStatusMap[detail.courseOrderStatus] }}
    </div>
    <div class="course-title">{{ detail.courseName }}</div>
    <div class="label-value-list">
      <div class="label-value-item">
        <div class="label-text">开班人数：</div>
        <div v-if="detail.isAstrictPersons === 1" class="value-text">{{ detail.minPerson }} ~ {{ detail.maxPerson }}人</div>
        <div v-else class="value-text">不限人数</div>
      </div>
      <div class="label-value-item" v-if="detail.courseOrderStatus !== 2">
        <div class="label-text">当前报名：</div>
        <!-- <div class="value-text">{{ detail.applyCount }}人</div> -->
        <div class="value-text">{{ detail.courseStatus === 5 ? detail.applyUserCount : detail.applyCount }}人</div>
      </div>
      <div class="label-value-item">
        <div class="label-text">开课周期：</div>
        <div class="value-text">{{ detail.courseOpenStart }} ~ {{ detail.courseOpenEnd }}</div>
      </div>
    </div>
    <div class="card-footer">
      <div class="payment-amount">
        支付金额：<span>¥{{ detail.price }}</span>
      </div>
      <div class="button-list">
        <!-- <div class="button-hand ytk" v-if="[2, 3].includes(detail.courseOrderStatus)">已退款</div> -->
        <!-- <div class="button-hand qxzf" v-if="[5].includes(detail.courseOrderStatus)" @click.stop="canclePay">取消支付</div>
        <div class="button-hand qzf" v-if="[5].includes(detail.courseOrderStatus)" @click.stop="goPay">去支付</div> -->
        <!-- <div class="button-hand qxbm" v-if="[0, 1].includes(detail.courseOrderStatus) && !signUpTimeFlag" @click.stop="cancleSignUp">取消报名</div> -->
        <!-- <div class="button-hand qxbm" v-if="[1].includes(detail.courseOrderStatus)" @click.stop="cancleSignUp">取消报名</div> -->
        <!-- <div class="button-hand zfcg" v-if="[0, 1].includes(detail.courseOrderStatus)">支付成功</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { courseOrderStatusMap, courseOrderStatusClassMap } from '../map.js';
import { cancelMyCourseOrderPayApplyUrl } from '../api.js';

export default {
  name: 'courseOrderCard',
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      courseOrderStatusMap,
      courseOrderStatusClassMap
    };
  },
  computed: {
    signUpTimeFlag() {
      if (this.detail.applyDateEnd) {
        return new Date() > new Date(this.detail.applyDateEnd);
      } else {
        return false;
      }
    }
  },
  created() {},
  methods: {
    cardOnClick() {
      this.$router.push({
        name: 'myCourseOrderDetail',
        query: {
          id: this.detail.id
        }
      });
    },
    canclePay() {},
    goPay() {},
    cancleSignUp() {
      this.$dialog
        .confirm({
          title: '提示',
          message: '是否确定取消报名？',
          confirmButtonColor: '#3680FF'
          // beforeClose: (action, done) => {
          //   if (action === 'confirm') {
          //     this.cancelApplyHandle(done);
          //   } else {
          //     done();
          //   }
          // }
        })
        .then(() => {
          this.cancelApplyHandle();
        })
        .catch(() => {});
    },
    cancelApplyHandle(callback) {
      this.$axios
        .get(cancelMyCourseOrderPayApplyUrl, { params: { id: this.detail.id } })
        .then(res => {
          if (res && res.code === 200) {
            this.$toast(res.msg);
            callback && callback();
            this.$emit('qxbm-success', this.detail);
          } else {
            callback && callback(false);
          }
        })
        .catch(() => {
          callback && callback(false);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.course-order-card {
  box-sizing: border-box;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
  border-radius: 16px;
  padding: 24px 32px;
  position: relative;
  .course-order-status {
    box-sizing: border-box;
    width: 160px;
    height: 44px;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 16px 0px 44px;
    font-size: 24px;
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    &.gray {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
    }
    &.red {
      background: linear-gradient(180deg, #ed1d1d 0%, #ef6565 100%);
    }
    &.blue {
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    }
    &.green {
      background: linear-gradient(149deg, #43c93e 0%, #3ccc4d 100%);
    }
  }
  .course-title {
    box-sizing: border-box;
    font-size: 32px;
    line-height: 36px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.85);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 90px;
  }
  .label-value-list {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .label-value-item {
      box-sizing: border-box;
      min-width: 50%;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      font-size: 24px;
      line-height: 34px;
      margin-top: 20px;
    }
  }
  .card-footer {
    box-sizing: border-box;
    margin-top: 20px;
    border-top: 1px solid #eeeeee;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .payment-amount {
      box-sizing: border-box;
      flex-shrink: 0;
      font-size: 28px;
      color: #333333;
      line-height: 30px;
      span {
        box-sizing: border-box;
        color: #d9021b;
        font-weight: 700;
      }
    }
    .button-list {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
      .button-hand {
        box-sizing: border-box;
        height: 50px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 20px;
        font-size: 28px;
        line-height: 28px;
        margin-left: 10px;
      }
      .ytk {
        border: 1px solid #999999;
        color: #999999;
      }
      .qxbm,
      .qxzf {
        border: 1px solid #333333;
        color: #333333;
      }
      .zfcg {
        border: 1px solid #3ccb4b;
        color: #3ccb4b;
      }
      .qzf {
        color: #ffffff;
        background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
        box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
        height: 51px;
      }
    }
  }
}
</style>
