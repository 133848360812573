<template>
  <div class="my-course-list">
    <tabs-tab defaultName="kcdd" :tabsList="tabsList" />
    <div class="course-order-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        @load="getCourseOrderList"
      >
        <course-order-card v-for="(item, index) in courseOrderList" :key="'wdkeb' + index" :detail="item" @qxbm-success="qxbmOnSuccess" />
      </van-list>
    </div>
    <v-tabbar :active="1" />
  </div>
</template>

<script>
import tabsTab from '@/views/futureEdu/nightSchool/components/tabsTab.vue';
import vTabbar from '../components/v-tabbar.vue';
import courseOrderCard from '../components/courseOrderCard.vue';
import { getMyCourseOrderListUrl } from '../api.js';
import { mapState } from 'vuex';

export default {
  name: 'myCourseOrderList',
  components: { vTabbar, tabsTab, courseOrderCard },
  data() {
    return {
      tabsList: [
        {
          title: '课程表',
          name: 'kcb',
          routeName: 'myCourseSheetList'
        },
        {
          title: '课程订单',
          name: 'kcdd',
          routeName: 'myCourseOrderList'
        }
      ],
      courseOrderList: [],
      timer: null,
      currentDate: new Date(),
      loading: false,
      finished: false,
      error: false,
      total: 0,
      queryForm: {
        userId: '',
        curPage: 1,
        pageSize: 10
      }
    };
  },
  computed: {
    ...mapState(['userId'])
  },
  created() {
    this.queryForm.userId = this.userId;
    this.satrtInterval();
    this.getCourseOrderList();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    satrtInterval() {
      if (this.timer) clearImmediate(this.timer);
      this.timer = setInterval(() => {
        this.currentDate = new Date();
      }, 1000);
    },
    getCourseOrderList() {
      this.loading = true;
      this.finished = false;
      this.$axios
        .get(getMyCourseOrderListUrl, { params: this.queryForm })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            res.data.records.forEach(item => {
              // item.courseOrderStatus = item.payType === 0 ? 5 : item.applyStatus;
              item.courseOrderStatus = item.applyStatus;
            });
            this.courseOrderList.push(...res.data.records);
            this.queryForm.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.courseOrderList.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    qxbmOnSuccess() {
      this.courseOrderList = [];
      this.queryForm.curPage = 1;
      this.getCourseOrderList();
    }
  }
};
</script>

<style lang="less" scoped>
.my-course-list {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  padding-top: 90px;
  padding-bottom: 96px;
  background-color: #fafafa;
}
.course-order-list {
  box-sizing: border-box;
  height: 100%;
  padding: 30px 30px 30px 30px;
  overflow-x: hidden;
  overflow-y: auto;
  .course-order-card {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

/deep/ .van-tabs {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
}
/deep/ .van-tab--active {
  font-weight: 700;
}
</style>
